import axios from "axios"
import { getCountNotifications} from "./StaticGetters"

export const getNotifications=async()=>{
    let data= await getCountNotifications()
    let dict = {
        'pending_expense': data.pending_expense,
        'approved_expense': data.approved_expense,
        'transfer_expense': data.transfer_expense,
        'pre_auth_expense': data.pre_auth_expense
    }
    sessionStorage.setItem('notifications', JSON.stringify(dict))
}