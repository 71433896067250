import { Input, Table } from "antd";
import { parse } from "date-fns";
import React from "react";
import dayJS from "dayjs";

const ExpenseDetail = ({ dados }) => {

    const columnsReceipts = [
        {
            title: 'Receita',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            key: 'value',
            width: 170,
            align: 'right'
        },
    ]

    const columnsHist = [
        {
            title: 'Usuário',
            dataIndex: 'user',
            render: (text, record) => `${record?.user?.first_user_name} ${record?.user?.last_user_name}`,
            key: 'user',
        },
        {
            title: 'Acção',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <p>{record.action} {record.message ? `--Mensagem: ${record.message}` : ''}</p>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 130,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
    ]

    const totalArrecadado = () => {
        let list = dados?.revenue
        let total = 0
        list.forEach(receita => {
            total += parseFloat(receita.value)
        })
        return total
    }

    return (<>
        <div className="w-full flex flex-col">
            <div className='grid grid-cols-2 gap-4 w-full mt-2 mb-2'>
                <div className='flex flex-col'>
                    <p className='label-see'>Referencia</p>
                    <Input
                        className='input-see'
                        value={dados?.reference}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Unidade Organica</p>
                    <Input
                        className='input-see'
                        value={dados?.unit?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Rubrica</p>
                    <Input
                        className='input-see'
                        value={dados?.expense?.codigo + ' - ' + dados?.expense?.nome}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Eixo</p>
                    <Input
                        className='input-see'
                        value={dados?.objective?.eixo?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Objectivo Estrategico</p>
                    <Input
                        className='input-see'
                        value={dados?.objective?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Fornecedor</p>
                    <Input
                        className='input-see'
                        value={dados?.supply?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Valor</p>
                    <Input
                        className='input-see'
                        value={`MZN ${parseFloat(dados?.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Descrição</p>
                    <Input
                        className='input-see'
                        value={dados?.description}
                        disabled
                    />
                </div>

            </div>
            <p className="title-form">Histórico</p>
            <Table
                className='custom-table mt-2 mb-0'
                size="small"
                columns={columnsHist}
                dataSource={dados?.logs}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                locale={{
                    emptyText: 'Sem histórico'
                }}
            />
        </div>
    </>)
}

export default ExpenseDetail;