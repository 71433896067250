// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Divider, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getProfilePicture } from '../../API/StaticGetters';

const Auth = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    verifyToken()
  }, [])

  const verifyToken = async () => {
    axios.post('https://api.girp.uem.mz/api/auth/ldap/',
      {
        token: token
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        const data = response.data
        setLoading(false)
        localStorage.setItem('title', 'Bem-vindo a Plataforma');
        localStorage.setItem('type', '1');
        localStorage.setItem('url', `https://api.girp.uem.mz`);
        if ([11,8,9,7,10,2,13].includes(data.groups[0].id)) {
          sessionStorage.setItem('token', data.token)
          sessionStorage.setItem('user', JSON.stringify(data))
          sessionStorage.setItem('nuit', data.nuit)
          sessionStorage.setItem('access', data.groups[0].id)
          navigate('/', { replace: true })
        } else {
          notification.error({
            message: 'Erro',
            description: 'Você não tem permissão para acessar esta plataforma'
          })
          navigate('/account/login', { replace: true })
        }

      }).catch((error) => {
        notification.error({
          message: 'Erro',
          description: 'Token inválido ou expirado.',
        })
        navigate('/account/login', { replace: true })
      })
  }

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
      <div className="flex h-screen bg-white sm:bg-gray-100">
        <div className="m-auto w-full sm:w-auto">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            className="bg-white sm:shadow-md px-8 pt-0 pb-8 sm:w-96"
            style={{ borderRadius: '15px' }}
          >
            <div className='flex flex-row justify-center items-center gap-2 mb-2 pt-6 pb-2'>
              <img src={require('../../assets/logo/logo-v2.png')} alt="logo" className="w-40 sm:w-16" />
              <Divider type="vertical" style={{ height: '100px', borderWidth: '1.5px', borderColor: 'green' }} />
              <img src={require('../../assets/logo/logoGirp.jpg')} alt="logo" className="w-40 sm:w-34" />
            </div>
            <p className="text-center text-lg sm:text-lg font-bold mb-1" style={{ color: 'black' }}>Autenticando...</p>
            <div className='w-full flex justify-center mt-4'>
              <Spin
                spinning={true}
                size='large'
                tip="Carregando..."
              />
            </div>

          </Form>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Auth;
